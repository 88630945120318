/* eslint-disable import/order */
/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react"
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import Comments from '../components/Post/Comments';
import { Button } from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';


const TAX_RATE = 0.07;

const classes = makeStyles({
  table: {
    minWidth: 700,
  },
});

const wilays=["أدرار ",
"الشلف ",
"الأغواط ",
"أم البواقي ",
"باتنة ",
"بجاية ",
"بسكرة ",
"بشار ",
"البليدة ",
"البويرة ",
"تمنراست ",
"تبسة ",
"تلمسان ",
"تيارت ",
"تيزي وزو ",
"الجزائر ",
"الجلفة ",
"جيجل ",
"سطيف ",
"سعيدة ",
"سكيكدة ",
"سيدي بلعباس ",
"عنابة ",
"قالمة ",
"قسنطينة ",
"المدية ",
"مستغانم ",
"المسيلة ",
"معسكر ",
"ورقلة ",
"وهران ",
"البيض ",
"اليزي ",
"برج بو عريريج ",
"بومرداس ",
"الطارف ",
"تندوف ",
"تيسمسيلت ",
"الواد ",
"خنشلة ",
"سوق أهراس ",
"تيبازة ",
"ميلة ",
"عين الدفلى ",
"النعامة ",
"عين تيموشنت ",
"غرداية ",
"غيليزان "]

const grades = [ 
"أستاذ التعليم العالي ",
"أستاذ محاضر قسم أ",
"أستاذ محاضر قسم ب",
"أستاذ مساعد قسم أ",
"أستاذ مساعد قسم ب",
"معيد",
]

const ValidationTextField = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  },
})(TextField);
class UserInfo extends React.Component {

  constructor(props) {

    super(props);
    const outputs = {new:{
      Trait: 0,
      IEP: 0,
      IDoc: 0,
      IESP: 0,
      IQS: 0,
      ISP: 0,
      IZG: 0,
      IL: 0,
      IUS: 0,
      AF: 0,
      SS: 0,
      IRG: 0,
      Trait_Net: 0,
      PAPPS: 0,
      PAPPS3: 0,
      Trait_Fin: 0,
      responsability:0
    },old:{
      Trait: 0,
      IEP: 0,
      IDoc: 0,
      IESP: 0,
      IQS: 0,
      ISP: 0,
      IZG: 0,
      IL: 0,
      IUS: 0,
      AF: 0,
      SS: 0,
      IRG: 0,
      Trait_Net: 0,
      PAPPS: 0,
      PAPPS3: 0,
      Trait_Fin: 0,
      responsability:0
    }}

  

    this.state = {
      wilaya: 1,
      grade: 0,
      echelon: 0,
      logement: false,
      sit_fam: false,
      sit_unique: false,
      nbr_enf: 0,
      prime: 0,
      responsability:0,
      ...outputs
    }
  }

  handleWilayaChange(e) {
    this.setState({
      wilaya: e.target.value
    }, () => {
      this.calc()
    });

  }
  handleGradeChange(e) {
    this.setState({
      grade: e.target.value
    }, () => {
      this.calc()
    });
  }
  handleEchelonChange(e) {
    this.setState({
      echelon: e.target.value
    }, () => {
      this.calc()
    });

  }
  handleLogementChange(e) {
    this.setState({
      logement:  e.target.value
    }, () => {
      this.calc()
    });

  }
  
  
  
  handleSit_famChange(e) {
    this.setState({
      sit_fam: e.target.value
    }, () => {
      this.calc()
    });

  }

  handleSit_uniqueChange(e) {
    this.setState({
      sit_unique: e.target.value
    }, () => {
      this.calc()
    });

  }

  handleNbr_enfChange(e) {
    this.setState({
      nbr_enf: e.target.value
    }, () => {
      this.calc()
    });

  }

  handlePrimeChange(e) {
    this.setState({
      prime: e.target.value
    }, () => {
      this.calc()
    });

  }

  handleResponsabilityChange(e) {
    this.setState({
      responsability: e.target.value
    }, () => {
      this.calc()
    });

  }

 
  calc2(input, params) {
    const { pi, I_Doc, IZG, IESP_a, IQS_a, IEP_Percentage, Ind_Min, primesResponsibility } = params;
    let i = input.grade;
    let j = input.echelon;
    let k = input.prime;
    let w = input.wilaya;
    let zg = 0;
    let lgt = input.logement;
    // Calcul de l'indemnit  du poste specifique

    if ((w == 1) || (w == 11) || (w == 33) || (w == 37)) {
        zg = 1.5;
    } else if ((w == 8) || (w == 30) || (w == 32) || (w == 47)) {
        zg = 1.2;
    } else if ((w == 3) || (w == 39) || (w == 45)) {
        zg = 1.0;
    } else if ((w == 4) || (w == 5) || (w == 7) || (w == 12) || (w == 14) || (w == 17) || (w == 20) || (w == 24) || (w == 28) || (w == 38) || (w == 40) || (w == 41)) {
        zg = 0.8;
    } else {
        zg = 0;
    }

    // Calcul de la zone g ographique
    let pzg=0;
    if ((w == 1) || (w == 37) || (w == 33)) {
        pzg = .35;
    } else if ((w == 11) || (w == 30) || (w == 32) || (w == 47)) {
        pzg = .315;
    } else if ((w == 32) || (w == 45)) {
        pzg = .28;
    } else if ((w == 3) || (w == 47)) {
        pzg = .245;
    } else if ((w == 7) || (w == 8) || (w == 17) || (w == 30) || (w == 39) || (w == 32)) {
        pzg = .21;
    } else if ((w == 0)) {
        pzg = .175;
    } else if ((w == 0)) {
        pzg = .17;
    } else if ((w == 0)) {
        pzg = .105;
    }
   

    // Calcul des Gains
    let Trait_Base = Ind_Min[i] * pi;

  
    
    let Trait = (Ind_Min[i] + Math.round(j * 0.05 * Ind_Min[i])) * pi;
  


    let IEP = IEP_Percentage* Trait_Base * j;


    let Doc = I_Doc[i];

    let SP = Trait * zg;

    let ZG = IZG[i] * 10 * pzg;

    let L=0;
    if (lgt === false) {
        if (zg == 1.5) {
            L = 2000.;

        } else if ((zg == 1.2) || (zg == 1.0) || (zg == 0.8)) {
            L = 1500.;

        } else {
            L = 0;

        }
    } 
    let IUS = 0;

    if ((input.sit_fam == true) && (input.nbr_enf == 0)) {
        IUS = 5.5;

    } else if ((input.sit_fam == true) && (input.sit_unique == false)&& (input.nbr_enf > 0)) {
        IUS = input.sit_fam * 800;
    };
    let AF = input.nbr_enf * 300;

    let IESP = IESP_a[i] * Trait;

    let IQS = IQS_a[i] * Trait;


    let responsability = 0;
   // console.log(input);
   if(input.responsability>0) {
 
    responsability = primesResponsibility[input.responsability]*pi;
    console.log(input, responsability);
  }



    //let IP = Trait_Base * 0.15;
    
    let Trait_Brut = Trait + IEP + Doc + SP + ZG + L + IUS + AF + IESP + IQS+ responsability;


    // Calcul des Retenus
    let SS = 9 * (Trait_Brut - (IUS + AF)) / 100;
    SS = SS


    let Trait_Brut_Imp = Trait_Brut - SS - (IUS + AF + ZG + L);
    //let Trait_Brut_Imp = Trait_Brut - SS - ZG;

    Trait_Brut_Imp=Trait_Brut_Imp-Trait_Brut_Imp % 5


    function getIRG(Trait_Brut_Imp){
      let IRG
      if(Trait_Brut_Imp <= 30000){
        IRG = 0 
      }else if(Trait_Brut_Imp>30000 && Trait_Brut_Imp<40000){
        IRG=((Trait_Brut_Imp-30000)*0.30+2500)*5/3-12500/3;
      }
  
      else if (Trait_Brut_Imp>=40000 && Trait_Brut_Imp<=120000){
        IRG=(Trait_Brut_Imp-30000)*0.30+2500;
      }
      else {
        IRG= (Trait_Brut_Imp-120000)*0.35+29500;
      }
      return IRG;
    }
    

    function getIRG(salairBrute){
      let IRG=0
      let salaireRestant = salairBrute-20000;

      if(salaireRestant>0) {
        const tranche= 20000;
        const taux = 0.23
        const newSalaireRestant=salaireRestant<=tranche ? 0:salaireRestant-tranche;
        IRG+=(salaireRestant-newSalaireRestant)*taux
        salaireRestant=newSalaireRestant
      }

      if(salaireRestant>0) {
        const tranche= 40000;
        const taux = 0.27
        const newSalaireRestant=salaireRestant<=tranche ? 0:salaireRestant-tranche;
        IRG+=(salaireRestant-newSalaireRestant)*taux
        salaireRestant=newSalaireRestant
      }

      if(salaireRestant>0) {
        const tranche= 80000;
        const taux = 0.30
        const newSalaireRestant=salaireRestant<=tranche ? 0:salaireRestant-tranche;
        IRG+=(salaireRestant-newSalaireRestant)*taux
        salaireRestant=newSalaireRestant
      }

      if(salaireRestant>0) {
        const tranche= 160000;
        const taux = 0.33
        const newSalaireRestant=salaireRestant<=tranche ? 0:salaireRestant-tranche;
        IRG+=(salaireRestant-newSalaireRestant)*taux
        salaireRestant=newSalaireRestant
      }

      if(salaireRestant>0) {
        const tranche= 160000;
        const taux = 0.33
        const newSalaireRestant=salaireRestant<=tranche ? 0:salaireRestant-tranche;
        IRG+=(salaireRestant-newSalaireRestant)*taux
        salaireRestant=newSalaireRestant
      }

      if(salaireRestant>0) {
        IRG+=salaireRestant*0.35
      }

      let abbatement = IRG*0.4; 
      if(abbatement>1500) abbatement=1500;
      if(abbatement<1000) abbatement=1000;

      IRG-=abbatement;

      if(salairBrute > 30000 && salairBrute < 35000) {
      IRG= Math.round(IRG*137/51-27925/8);
      }


      return IRG;
    }
 

    let IRG = getIRG(Trait_Brut_Imp);



    if (zg == 1.5) {
        IRG = IRG / 2;

    };

    let Trait_Net = Trait_Brut - SS - IRG;
   

    // Calcul de la Prime
    
    let PAPPS0 = Trait * k / 100;
    let R_PAPPS_SS = PAPPS0 * 0.09;
    
    
    let IRG_Rate = 0.1
    if ((w == 1) || (w == 11) || (w == 33) || (w == 37)) {
      IRG_Rate = 0.05;
    }

    let R_PAPPS_IRG = IRG_Rate * (PAPPS0 - R_PAPPS_SS);


    let PAPPS = PAPPS0 - R_PAPPS_SS - R_PAPPS_IRG;
    let PAPPS3 = 3 * PAPPS;
    let Trait_Fin = Trait_Net + PAPPS;
   
    
    Trait=    Trait.toFixed(2);
    IEP=    IEP.toFixed(2);
    Doc=    Doc.toFixed(2);
    IESP=    IESP.toFixed(2);
    IQS=    IQS.toFixed(2);
    SP=    SP.toFixed(2);
    ZG=    ZG.toFixed(2);
    L=    L.toFixed(2);
    IUS=    IUS.toFixed(2);
    AF=    AF.toFixed(2);
    Trait_Net=    Trait_Net.toFixed(2);
    PAPPS=    PAPPS.toFixed(2);
    PAPPS3=    PAPPS3.toFixed(2);
    Trait_Fin = Trait_Fin.toFixed(2);
    IRG = IRG.toFixed(2);
    SS = SS.toFixed(2);
    responsability=responsability.toFixed(2);
    // Affichage


    const output = {
        Trait,
        IEP,
        IDoc:Doc,
        IESP,
        IQS,
        ISP:SP,
        IZG:ZG,
        IL:L,
        IUS,
        AF,
        SS,
        IRG,
        Trait_Net,
        PAPPS,
        PAPPS3,
        responsability,
        Trait_Fin
    }
    return output
}

  calc() {
    const input = {
      echelon: this.state.echelon,
      grade: this.state.grade,
      logement: this.state.logement,
      nbr_enf: this.state.nbr_enf,
      prime: this.state.prime,
      sit_fam: this.state.sit_fam,
      sit_unique:this.state.sit_unique,
      wilaya: this.state.wilaya,
      responsability:this.state.responsability
    }

    // const Ind_Min =[1530., 1330., 1175., 1105., 980., 628.];
    // const primesResponsibilityOld = [0,1220,740,1028,625,625,383,515,425,345,1028,625,617,378,378];
    // const oldCalc=this.calc2(input,Ind_Min,primesResponsibilityOld);

    // Old
    let Ind_Min =[1680.0, 1480.0, 1325.0, 1255.0, 1130.0, 778.0];
    let primesResponsibility = [0, 1290, 810, 1098, 695, 695, 453, 585, 495, 415, 1098, 695, 687, 448, 448];
    let pi = 45;
    let I_Doc = [16000., 14000., 12000., 8000., 6000., 3000.];
    let IZG = [1280., 1120., 1040., 960., 880., 534.];
    let IESP_a = [0.6, 0.5, 0.45, 0.3, 0.25, 0.2];
    let IQS_a = [0.4, 0.3, 0.25, 0.15, 0.1, 0];
    let IEP_Percentage = 0.04;
    let params={Ind_Min,primesResponsibility,pi,I_Doc,IZG,IESP_a,IQS_a,IEP_Percentage}
    const oldCalc=this.calc2(input,params);
   // NEw 
  
    Ind_Min =[1680.0, 1480.0, 1325.0, 1255.0, 1130.0, 778.0];
    primesResponsibility = [0, 1290, 810, 1098, 695, 695, 453, 585, 495, 415, 1098, 695, 687, 448, 448];
    pi = 45;
    I_Doc = [25000., 20500., 16500., 12000., 10000., 4000.];
    IZG = [1280., 1120., 1040., 960., 880., 534.];
    IESP_a = [0.85, 0.7, 0.55, 0.35, 0.30, 0.25];
    IQS_a = [0.6, 0.45, 0.35, 0.20, 0.15, 0];
    IEP_Percentage = 0.07;

    params={Ind_Min,primesResponsibility,pi,I_Doc,IZG,IESP_a,IQS_a,IEP_Percentage}
    const newCalc= this.calc2(input,params);
    
    const output={new:newCalc,old:oldCalc}

    this.setState(output)
  }

  getRows(){
      return [
    {label:"   الأجر الرئيسي	", value:this.state.old.Trait,value2:this.state.new.Trait, type:1},
    {label:"    تعويض الخبرة البيداغوجية	", value:this.state.old.IEP,value2:this.state.new.IEP, type:1},
    {label:"    تعويض التوثيق	", value:this.state.old.IDoc,value2:this.state.new.IDoc, type:1},
    {label:"    التعويض النوعي للمنصب	", value:this.state.old.ISP,value2:this.state.new.ISP, type:1},
    {label:"    تعويض المنطقة الجغرافية	", value:this.state.old.IZG ,value2:this.state.new.IZG , type:1},
    {label:"    تعويض السكن الوظيفي	", value:this.state.old.IL,value2:this.state.new.IL, type:1},
    {label:"    تعويض الأجر الوحيد	", value:this.state.old.IUS, value2:this.state.new.IUS,type:1},
    {label:"    المنح العائلية	", value:this.state.old.AF,value2:this.state.new.AF, type:1},
    {label:"    تعويض التأطير و المتابعة البيداغوجية	", value:this.state.old.IESP,value2:this.state.new.IESP, type:1},
    {label:"    تعويض التأهيل العلمي", value:this.state.old.IQS ,value2:this.state.new.IQS , type:1},
    {label:"    منحة المنصب العالي", value:this.state.old.responsability ,value2:this.state.new.responsability , type:1},
    {label:"    الضمان الاجتماعي	", value:this.state.old.SS,value2:this.state.new.SS, type:2},
    {label:"     الضريبة على الدخل الشامل	", value:this.state.old.IRG,value2:this.state.new.IRG, type:2},
    {label:"   الأجر الشهري الصافي بدون احتساب المردودية	", value:this.state.old.Trait_Net,value2:this.state.new.Trait_Net, type:3},
    {label:"   تعويض تحسين الأداء البيداغوجي و العلمي(شهريا)", value:this.state.old.PAPPS,value2:this.state.new.PAPPS, type:1},
    {label:"    تعويض تحسين الأداء البيداغوجي و العلمي(ثلاثة أشهر)", value:this.state.old.PAPPS3,value2:this.state.new.PAPPS3, type:1},
    {label:"    الأجر الشهري الصافي مع احتساب المردودية", value:this.state.old.Trait_Fin,value2:this.state.new.Trait_Fin, type:3},
]  
}


  render() {
  
    const siteTitle = "الباحث"
    const pageTitle = " محاكاة أجر الأستاذ الجامعي الجزائري  2024  النسخة 7.3 - جديد القانـون الأساسـي الخاص بالأستـاذ الباحـث  "
    const slug = "/محاكاة-أجر-الأستاذ-الجامعي-الجزائري"
    return (
<Layout title={ `${pageTitle} - ${siteTitle}` }>

<Sidebar/>

<Page title={ pageTitle }>
    <div>

    <Autocomplete
      id="combo-box-demo"
      options={wilays}
      getOptionLabel={(option) => option}
      style={{ width: "auto"}}
      value={wilays[this.state.wilaya-1]}
      onChange={(event, newInputValue) => {
        this.setState({
          wilaya:wilays.indexOf(newInputValue)+1
        }, () => {
          this.calc()
        });
      }}
      renderInput={(params) => <TextField {...params}  label="الولاية" variant="outlined" />}
    />
      <div><TextField
          style={{  width: "50%", marginTop: 30}}
          id="standard-select-currency"
          select
          label="الرتبة"
          value={this.state.grade }
          variant="outlined"
          // value={currency}
          onChange={this.handleGradeChange.bind(this)}
          helperText="من فضلك اختر ولاية"
        >
           {grades.map((w,i)=>(
                <MenuItem value={i}>{w}</MenuItem>
            ))}
        </TextField>
        
        <TextField
          style={{ width: "50%", marginTop: 30 }}
          id="standard-select-currency"
          select
          label="الدرجة"
          value={this.state.echelon }
          variant="outlined"
          // value={currency}
          onChange={this.handleEchelonChange.bind(this)}
          helperText="من فضلك اختر الدرجة"
        >
           {[0,1,2,3,4,5,6,7,8,9,10,11,12].map((w,i)=>(
                <MenuItem value={w}>{w}</MenuItem>
            ))}
        </TextField></div>
        


        <TextField
          style={{ width: "50%", marginTop: 30}}
          id="standard-select-currency"
          select
          label="نسبة المردودية"
          value={this.state.prime }
          variant="outlined"
          // value={currency}
          onChange={this.handlePrimeChange.bind(this)}
          helperText="من فضلك اختر نسبة المردودية"
        >
           {[0,5,10,15,20,25,30,35,40].map((w,i)=>(
                <MenuItem value={w}>{w}%</MenuItem>
            ))}
        </TextField>


        <TextField
          style={{ width: "50%", marginTop: 30}}
          id="standard-select-currency"
          select
          label=" (تجريبي) المنصب العالي"
          value={this.state.responsability }
          variant="outlined"
          onChange={this.handleResponsabilityChange.bind(this)}
          helperText=" المنصب العالي "
        >
           {["دون منصب عالي","رئيس جامعة","نائب رئيس الجامعة","عميد كلية","نائب عميدالكلية","رئيس قسم","نائب رئيس قسم","مسؤول ميدان","مسؤول شعبة","مسؤول تخصص","مدير مركز جامعي","مدير مساعد مركز جامعي","مدير معهد لمركز جامعي","مدير مساعد معهد لمركز جامعي","رئيس قسم معهد لمركز جامعي"].map((w,i)=>(
                <MenuItem value={i}>{w}</MenuItem>
            ))}
        </TextField>


        <TextField
          style={{  width: "100%" , marginTop: 30}}
          id="standard-select-currency"
          select
          label="السكن الوظيفي"
          value={this.state.logement }
          variant="outlined"
          // value={currency}
          onChange={this.handleLogementChange.bind(this)}
          helperText="هل انت مستفيد من السكن الوظيفي ؟"
        >
           {[{label:"غير مستفيد",value:false},{label:"مستفيد",value:true}].map((w,i)=>(
                <MenuItem value={w.value}>{w.label}</MenuItem>
            ))}
        </TextField>
        
        <TextField
          style={{  width: "33%" , marginTop: 30}}
          id="standard-select-currency"
          select
          label="الحالة العائلية"
          value={this.state.sit_fam }
          variant="outlined"
          // value={currency}
          onChange={this.handleSit_famChange.bind(this)}
          helperText="ما هي حالتك العائلية ؟"
        >
           {[{label:"أعزب",value:false},{label:"متزوج",value:true}].map((w,i)=>(
                <MenuItem value={w.value}>{w.label}</MenuItem>
            ))}
        </TextField>   

        <TextField
          style={{  width: "33%" , marginTop: 30}}
          id="standard-select-currency"
          select
          label=" الزوج /الزوجة"
          value={this.state.sit_unique }
          variant="outlined"
          // value={currency}
          onChange={this.handleSit_uniqueChange.bind(this)}
          helperText="هل هو موظف(ة)؟"
        >
           {[{label:"دون عمل",value:false},{label:"(ة)موظف",value:true}].map((w,i)=>(
                <MenuItem value={w.value}>{w.label}</MenuItem>
            ))}
        </TextField>    


        <TextField
          style={{  width: "33%" , marginTop: 30}}
          id="standard-select-currency"
          select
          label="عدد الأطفال"
          value={this.state.nbr_enf}
          variant="outlined"
          // value={currency}
          onChange={this.handleNbr_enfChange.bind(this)}
          helperText="من فضلك اختر عدد الأطفال "
        >
           {[0,1,2,3,4,5,6].map((w,i)=>(
                <MenuItem value={w}>{w}</MenuItem>
            ))}
        </TextField>

        <h3>الراتب جانفي 2024</h3>
        <ValidationTextField
        className={classes.margin}
        value={this.state?.old?.Trait_Net+" .د.ج"}  label="راتب بدون مردودية" 
        required
        variant="outlined"
        defaultValue="Success"
        id="validation-outlined-input"
        style={{width:"50%",  borderColor: 'green',pointerEvents:"none", marginTop: 40}}
      />
  
        <ValidationTextField
        className={classes.margin}
        value={this.state?.old?.Trait_Fin+" .د.ج"}  label="راتب بالمردودية" 
        required
        variant="outlined"
        defaultValue="Success"
        id="validation-outlined-input"
        style={{width:"50%",  borderColor: 'green',pointerEvents:"none", marginTop: 40}}
      />

<h3>الراتب الجديد 2024</h3>
  
      <ValidationTextField
        className={classes.margin}
        value={this.state?.new?.Trait_Net+" .د.ج"}  label="راتب بدون مردودية" 
        required
        variant="outlined"
        defaultValue="Success"
        id="validation-outlined-input"
        style={{width:"50%",  borderColor: 'green',pointerEvents:"none", marginTop: 40}}
      />
  
        <ValidationTextField
        className={classes.margin}
        value={this.state?.new?.Trait_Fin+" .د.ج"}  label="راتب بالمردودية" 
        required
        variant="outlined"
        defaultValue="Success"
        id="validation-outlined-input"
        style={{width:"50%",  borderColor: 'green',pointerEvents:"none", marginTop: 40}}
      /> 

     </div>
     <h1> كشف الراتب</h1>
     <p> نتائج المحاكاة تقريبية و من الوارد وقوع أخطاء في الحساب. يمكنك مراسلتنا في حالة اكتشاف أي اخطاء عبر ارفاق البيانات المدرجة و النتيجة الحقيقية.</p>
     <p> راتب شهر جانفي تم  باحتساب الأجور الجديدة بزيادة 75 نقطة الى الرقم الإستدلالي لكل صنف.</p>
     <p>  2024 تم احتساب الأجر الجديد حسب قانون الأستاذ الباحث الصادر في الجريدة الرسمية العدد 18 .</p>
     <p>  تم اضافة التخفيض الضريبي لفائدة ولايات الجنوب الكبير العدد 11 2014 
     </p>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={1}>
              <h3>عناصر الراتب</h3>
            </TableCell>
            <TableCell  align="center">  <h3>  -قبل- المنح و التعويضات</h3></TableCell>
            <TableCell  align="center">  <h3>   -بعد- المنح و التعويضات</h3></TableCell>
            <TableCell  align="center">  <h3>  الفرق</h3></TableCell>
  
  
  
          </TableRow>
        </TableHead>
        <TableBody>
        {this.getRows().map((row) => (
          <TableRow    style={{fontSize: "large",fontWeight:900}}>
          <TableCell align="center"  style={{ fontSize: "large"}}>
             {row.label}
            </TableCell>

            <TableCell   style={{ fontSize: "large",fontWeight:750,color: row.type===2?"red":"black", backgroundColor: row.type===3?"lightgreen":"white"}}  align="center">{row.value} .د.ج</TableCell>
            <TableCell   style={{ fontSize: "large",fontWeight:750,color: row.type===2?"red":"black", backgroundColor: row.type===3?"lightgreen":"white"}}  align="center">{row.value2} .د.ج</TableCell>
            <TableCell   style={{ fontSize: "large",fontWeight:750,color: row.type===2?"red":"black", backgroundColor: row.type===3?"lightgreen":"white"}}  align="center">{Math.round(row.value2-row.value)} .د.ج</TableCell>

          </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    <Comments postSlug={ slug } postTitle={ pageTitle } />
</Page>


</Layout>
    );
  }
}

export default UserInfo;